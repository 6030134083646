var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-pricing flex",class:{
    'flex-col gap-0': _vm.isVerticalPrice,
    'lg:flex-col-reverse': _vm.isVerticalPrice,
    'gap-x-2 items-baseline flex-wrap': !_vm.isVerticalPrice,
    'items-center': _vm.isPriceCentered
  }},[_c('span',{staticClass:"product-pricing__price",class:[{
      'text-EXPONDO-red': _vm.isDiscountDisplayed,
      'text-EXPONDO-black': !_vm.isDiscountDisplayed,
      'lg:text-center lg:order-first': !_vm.isVerticalPrice
    }, _vm.primaryPriceSize],attrs:{"data-testid":"productPricing"}},[_vm._v("\n    "+_vm._s(_vm.primaryPrice)+"\n  ")]),_vm._v(" "),(_vm.isDiscountDisplayed && !_vm.hideStrikethroughPrice)?_c('span',{staticClass:"product-pricing__secondary-price text-EXPONDO-black leading-6",class:[{
      'hidden md:block': _vm.isCart && !_vm.readOnly
    }, _vm.secondaryPriceSize]},[_vm._v("\n    "+_vm._s(_vm.secondaryPrice)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isActiveProduct)?_c('div',[_c('meta',{attrs:{"itemprop":_vm.SCHEMA_ITEM_PROP.PRICE,"content":_vm.currentPrice}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":_vm.SCHEMA_ITEM_PROP.PRICE_CURRENCY,"content":_vm.priceCurrency}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }