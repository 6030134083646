export const TWICPICS_MODE = {
  COVER: 'cover',
  CONTAIN: 'contain'
} as const;

export type TwicpicsMode = ValueOf<typeof TWICPICS_MODE>;

export type TwicpicsParams = {
    mode: string,
    width: number,
    height: number
}
